import { Button, Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { SinRegistros } from "../../../components/sinRegistros/SinRegistros";
import { dateFromString } from "../../../utils/dateFromString";
import { formatCurrency } from "../../../utils/formatCurrency";
import { Servicios } from "./_Servicios";
import { Factura } from "./_Factura";
import { Complemento } from "./_Complemento";
import { FilePdfOutlined } from "@ant-design/icons";
import { Comprobante } from "./_Comprobante";

export const Listado = () => {
  const { ordenesAbiertas } = useSelector((state) => state.facturacionReducer);

  //
  return (
    <>
      <Row gutter={10} style={{ marginTop: 10 }}>
        <Col className="tituloGrid" span={3}>
          Orden de Pago
        </Col>
        <Col className="tituloGrid" span={3}>
          Creación
        </Col>
        <Col className="tituloGrid" span={3}>
          Monto
        </Col>
        <Col className="tituloGrid" span={3}>
          Servicios
        </Col>

        <Col className="tituloGrid" span={3}>
          Factura
        </Col>
        <Col className="tituloGrid" span={3}>
          Autorizada
        </Col>
        <Col className="tituloGrid" span={3}>
          Pagada
        </Col>
        <Col className="tituloGrid" span={2}>
          Complemento
        </Col>
      </Row>

      {ordenesAbiertas.lenght === 0 ? (
        <SinRegistros />
      ) : (
        ordenesAbiertas.map((o, i) => (
          <Row
            key={i}
            gutter={10}
            style={{ margin: "20px 0", borderBottom: "1px solid #f1f1f1" }}
            align="top"
          >
            <Col span={3}>{o.ordenPagoID}</Col>
            <Col span={3}>{dateFromString(o.fechaCreacion).soloFecha}</Col>
            <Col span={3} className="celdaNumero">
              {formatCurrency(o.total)}
            </Col>
            <Col span={3}>
              <Servicios orden={o} />
            </Col>

            <Col span={3}>
              <Factura orden={o} />
            </Col>
            <Col span={3} className="celdaNumero">
              {dateFromString(o.fechaAutorizacionPago).soloFecha}
            </Col>
            <Col span={3} className="celdaNumero">
              <Comprobante orden={o} />
            </Col>
            <Col span={3}>
              <Complemento orden={o} />
            </Col>
          </Row>
        ))
      )}
    </>
  );
};
